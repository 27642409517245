import React, { useState } from 'react'
import goodifly from './assets/goodifly.png'
import baseUrl from "../../components/baseUrl.js";

const JoinCommunity = ({setCongrat}) => {
    const [joinedAs, setJoinedAs] = useState('')
    const [errorMessage, setErrorMessage] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [location, setLocation] = useState("")
    const [isChecked, setIsChecked] = useState(false);

    const toggleCheckbox = () => {
        setIsChecked(!isChecked);
    };

    // const handleSubmit = (e) =>{
    //     e.preventDefault();
    //     if(joinedAs.length<1){
    //         setErrorMessage("Joining Type is Required")
    //     }
    //     else {
    //         setErrorMessage("")
    //         const data = JSON.stringify({
    //             joinedAs:joinedAs,
    //             first_name: firstName,
    //             last_name: lastName,
    //             email: email,
    //             phone_number: "+" + phoneNumber,
    //             location: location

    //         })
    //         setCongrat(true)
    //     }

    // }
        // const handleSubmit = async (e) => {
        //   e.preventDefault();
        //   if (joinedAs.length < 1) {
        //     setErrorMessage("Joining Type is Required");
        //     return;
        //   }
        //   if (!isChecked) {
        //     setErrorMessage("Agree to Goodifly's terms");
        //     return;
        //   }

        //   setErrorMessage("");
        //   const data = {
        //     joinedAs: joinedAs,
        //     first_name: firstName,
        //     last_name: lastName,
        //     email: email,
        //     phone_number: "+" + phoneNumber,
        //     location: location,
        //   };

        //   try {
        //     const response = await fetch(
        //       "http://your-backend-url/api/join-community",
        //       {
        //         method: "POST",
        //         headers: {
        //           "Content-Type": "application/json",
        //         },
        //         body: JSON.stringify(data),
        //       }
        //     );

        //     if (response.ok) {
        //       // Assume setCongrat(true) is used to show a success message or redirect
        //       setCongrat(true);
        //     } else {
        //       const errorData = await response.json();
        //       setErrorMessage(
        //         errorData.message ||
        //           "Failed to join the community. Please try again."
        //       );
        //     }
        //   } catch (error) {
        //     setErrorMessage("An error occurred. Please try again later.");
        //   }
        // };
    const handleSubmit = (e) => {
      e.preventDefault();
      if (joinedAs.length < 1) {
        setErrorMessage("Joining Type is Required");
      } else if (!isChecked) {
        setErrorMessage("Agree to Goodifly's terms");
      } else {
        setErrorMessage("");
        const data = {
          joinedAs: joinedAs,
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone_number: phoneNumber,
          location: location,
        };

        fetch(`${baseUrl}/join-community/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "success") {
              setCongrat(true);
            } else {
              setErrorMessage(data.message);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setErrorMessage("An error occurred. Please try again.");
          });
      }
    };
    return (
        <div className='  w-full sm:w-[528px] rounded-t-[30px] xm:rounded-[30px] shadow-input bg-white pt-[29px] xm:pt-[34px]  xm:pb-[45px] px-8 xm:px-[2.875rem] relative'>
            <div className=' w-full flex flex-col text-center items-center justify-center'>
                <img src={goodifly} alt="" className=' w-[107px] h-[33px]' />
                <h1 className=' mt-[24px] font-bold text-[18px] leading-[21.83px]'>Join The Goodifly Community!</h1>
                <h1 className=' mt-[7px] xm:w-[354px] text-[#707070] text-[16px] leading-[19.41px]'>We're thrilled to have you embark on this journey
                    with us! To tailor your experience, let's get to
                    know you better.</h1>
            </div>

            <form method='post' onSubmit={handleSubmit} className=' flex flex-col items-center mt-[31px]'>
                <h1 className='font-bold text-[18px] leading-[21.83px]'>Are you joining as a</h1>
                <div className={` ${errorMessage==="Joining Type is Required"?"border-solid border-[1px] border-red-600":""}  p-2 rounded-full mt-[10px] flex gap-[17px]`}>
                    <div className=' flex gap-[6px]'>
                        <div onClick={() => {setJoinedAs('Traveller'); setErrorMessage('')}} className={` ${joinedAs === 'Traveller' ? " cursor-not-allowed border-[3px] border-[#E5E5E5] bg-[#EB0728]" : "cursor-pointer bg-white border-[2px] border-[#EB0728]"} w-[18px] h-[18px] rounded-full `} />
                        <h1 className=' text-[#707070] text-[16px] leading-[19.41px]'>Traveller</h1>
                    </div>
                    <div className=' flex gap-[6px]'>
                        <div onClick={() => {setJoinedAs('User'); setErrorMessage('')}} className={` ${joinedAs === 'User' ? " cursor-not-allowed border-[3px] border-[#E5E5E5] bg-[#EB0728]" : "cursor-pointer bg-white border-[2px] border-[#EB0728]"} w-[18px] h-[18px] rounded-full `} />
                        <h1 className=' text-[#707070] text-[16px] leading-[19.41px]'>User</h1>
                    </div>
                </div>
                {errorMessage==="Joining Type is Required"?<h1 className=' text-red-600  text-[12px] text-left'>Joining Type is Required</h1>:null}

                <div className=' mt-[35px] w-full flex flex-col gap-3 text-[15px] leading-[18.2px]'>
                    <div className='flex flex-col xm:flex-row  gap-[13px] w-full'>
                        <input required value={firstName} onChange={(e)=>setFirstName(e.target.value)} type="text" placeholder='First Name' className=' w-full h-[40px] bg-[#E5E5E5] rounded-[30px] outline-none placeholder:text-[#707070] px-[17px]' />
                        <input required value={lastName} onChange={(e)=>setLastName(e.target.value)} type="text" placeholder='Last Name' className=' w-full h-[40px] bg-[#E5E5E5] rounded-[30px] outline-none placeholder:text-[#707070] px-[17px]' />
                    </div>
                    <input required value={email} onChange={(e)=>setEmail(e.target.value)} type="email" placeholder='Email' className=' w-full h-[40px] bg-[#E5E5E5] rounded-[30px] outline-none placeholder:text-[#707070] px-[17px]' />
                    <div className='flex flex-col xm:flex-row gap-[13px] w-full'>
                        <input required value={phoneNumber} onChange={(e)=>setPhoneNumber(e.target.value)} type="number" placeholder='Phone Number' className=' appearance-none w-full h-[40px] bg-[#E5E5E5] rounded-[30px] outline-none placeholder:text-[#707070] px-[17px]' />
                        <input required value={location} onChange={(e)=>setLocation(e.target.value)} type="text" placeholder='Location' className=' w-full h-[40px] bg-[#E5E5E5] rounded-[30px] outline-none placeholder:text-[#707070] px-[17px]' />
                    </div>
                    {/** Terms Agreement */}
                    <div className=' w-full'>
                        <div className=' w-full items-center flex gap-[5px]'>
                            {/** Checkbox */}
                            <input required type="checkbox" name="agreement" id="agreement"
                                onChange={toggleCheckbox}
                                checked={isChecked}
                                className={` ${!isChecked ? ` appearance-none ${errorMessage === "Agree to Goodifly's terms" ? " border-[1px] border-[#EB0728]" : ""} ` : null} bg-[#D9D9D9] mt-2 rounded-[5px] w-[13px] h-[13px] checked:bg-white`} />
                            <h1 className=' text-[12px] font-normal mt-[7px] cursor-pointer text-[#707070]'>I agree to Goodifly’s terms and conditions and privacy policy</h1>
                        </div>
                        {errorMessage === "Agree to Goodifly's terms" ? <h1 className=' pl-5 w-full mt-[5px] text-red-600 text-[12px] text-left'>{errorMessage}</h1> : null}
                    </div>
                </div>
                <button className=' mt-[22px] w-full h-[40px] bg-[#EB0728] rounded-[30px] text-white text-[16px] leading-[19.41px]'>Join now</button>
            </form>
        </div>
    )
}

export default JoinCommunity
