import React, {useEffect} from 'react'
import { BookFlightFalse } from '../../features/flight/bookFlightSlice'
import { LogInTrue } from '../../features/nav/logInSlice'
import { useDispatch} from 'react-redux'
// import BookFlight from './BookFlight'
import PackageModal from './PackageModal/PackageModal'

const BookFlightModal = ({airports, username, userID,refresh_token, access_token,setAccessToken, flight, setBookingComplete, setPackageDirection, isMarketPlace, setIsMarketPlace }) => {
    const dispatch = useDispatch()


    const hideModal = (event) =>{
        const modal = document.getElementById('modal');
        // Check if the clicked element is outside of the modal
        if (event.target !== modal && !modal.contains(event.target)) {
            dispatch(BookFlightFalse()); // Call the function to close the modal
            setPackageDirection(null)
            setIsMarketPlace(false)
        }
    }

    // const [showModal, setShowModal] = useState(false);

    // const handleCloseModal = () => {
    //   setShowModal(false);
    // };

    useEffect(() => {

      const some_data = window.localStorage.getItem("jdgbgiusudgfdyudbudvfudhfgbiyfudvifiudubuydfbduvuydfvuy")
      if (!some_data) {
          dispatch(BookFlightFalse())
          dispatch(LogInTrue())
      }
  }, [dispatch])

    return (
      <div>
        <div
          onClick={hideModal}
          className=" cursor-pointer  h-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  z-[70] outline-none focus:outline-none scrollbar-hide"
        >
          <div id="modal" className="relative my-6 mx-[2%] sm:mx-0 ">
            {/*content*/}
            <div className=" cursor-default border-0 rounded-[30px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/* <BookFlight airports={airports} username={username} userID={userID} refresh_token={refresh_token} access_token={access_token} setAccessToken={setAccessToken} flight={flight} setBookingComplete={setBookingComplete} /> */}
              <PackageModal flight={flight} setPackageDirection={setPackageDirection} isMarketPlace={isMarketPlace} setIsMarketPlace={setIsMarketPlace} />
              {/* <PackageModal
                flight={flight}
                setPackageDirection={setPackageDirection} */}
            </div>
          </div>
        </div>
        <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
      </div>
    );
}

export default BookFlightModal
