
import React, { useState } from "react";
import goodifly from "../assets/goodifly.png";
import axios from "axios";
import baseUrl from "../../baseUrl";
import { useDispatch } from "react-redux";
import { sessionTrue } from "../../../features/pages/sessionSlice";

const FlaggedOutboundViewModal = ({
  access_token,
  setViewFlaggedOutboundViewModal,
  viewFlaggedOutboundViewModal,
  setViewSuccessOuboundModal,
}) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");
  const [disabled, setDisabled] = useState(false);

  const hideModal = (event) => {
    const modal = document.getElementById("modal");
    if (event.target !== modal && !modal.contains(event.target)) {
      setViewFlaggedOutboundViewModal(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);

    const data = {
      flight_book: viewFlaggedOutboundViewModal?.flight_book,
      item_description: viewFlaggedOutboundViewModal?.item_description,
      item_fee: viewFlaggedOutboundViewModal?.item_fee,
      item_weight: viewFlaggedOutboundViewModal?.item_weight,
      sender: viewFlaggedOutboundViewModal?.sender,
      carrier_acceptance_status: 3,
      agent_approval_outbound:
        parseInt(viewFlaggedOutboundViewModal?.agent_approval_outbound) + 4,
      agent_outbound_comment: comment,
    };

    try {
      const response = await axios.patch(
        `${baseUrl}/booking/${viewFlaggedOutboundViewModal.id}/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      if (response?.data?.id) {
        setViewFlaggedOutboundViewModal(null);
        setViewSuccessOuboundModal("This request has been flagged.");
      }
    } catch (error) {
      if (
        error?.response?.data?.messages?.[0]?.message ===
        "Token is invalid or expired"
      ) {
        dispatch(sessionTrue());
      }
      console.error(error);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <div>
      <div
        onClick={hideModal}
        className="fixed inset-0 z-[70] flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none scrollbar-hide"
      >
        <div
          id="modal"
          className="relative my-6 mx-[2%] sm:mx-0 w-full sm:w-auto"
        >
          <div className="relative flex flex-col w-full bg-white border-0 rounded-[30px] shadow-lg outline-none focus:outline-none">
            <div className="w-full sm:w-[518px] h-full min-h-[413px] rounded-[30px] px-[20px] sm:px-[47px] py-[40px] bg-[#ffffff]">
              <img src={goodifly} alt="logo" className="w-[107px] h-[33px]" />
              <h1 className="mt-[27px] font-bold text-[18px] text-black leading-[21.83px]">
                Flagged
              </h1>
              <form onSubmit={handleSubmit}>
                <textarea
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  required
                  name="comment"
                  id="comment"
                  placeholder="Leave a comment"
                  className="placeholder:text-[#AAAAAA] mt-[18px] px-[21px] py-[18px] resize-none w-full min-h-[168px] rounded-[20px] border-solid border-[1px] border-[#C1C1C180] text-[14px] leading-[16.98px]"
                ></textarea>
                <button
                  disabled={disabled}
                  type="submit"
                  className="disabled:bg-[#F4C5CC] disabled:cursor-not-allowed mt-[23px] w-full h-[40px] rounded-[80px] bg-[#EB0728] text-[16px] text-white leading-[19.41px] flex items-center justify-center"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-70 fixed inset-0 z-[60] bg-black"></div>
    </div>
  );
};

export default FlaggedOutboundViewModal;
