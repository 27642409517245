import React, { useState } from "react";
import baseUrl from "../../components/baseUrl.js";
import axios from "axios";
import { useDispatch } from "react-redux";
import { sessionTrue } from "../../features/pages/sessionSlice.js";
import PriceCalculator from "../../components/priceCalculator";
import CarrierPriceCalculator from "../../components/carrierPriceCalculator.js";
import RecipientDetails from "../../components/CustomerDashboard/Recipient.jsx";
import './MarketOrderSummary.css'

const MarketOrderSummary = ({
  access_token,
  flight,
  marketLinks,
  totalWeight,
  totalPrice,
  goBack,
  userID,
  username,
  phoneNumber,
}) => {
  const dispatch = useDispatch();
  //disable
  const [disabled, setDisabled] = useState(false);

  // Recipient details state
  const [isRecipientSameAsUser, setIsRecipientSameAsUser] = useState(false);
  const [recipientName, setRecipientName] = useState("");
  const [recipientPhone, setRecipientPhone] = useState("");

  //products id
  let productIds = [];

  const weight =
    parseFloat(flight?.minimum_space) < 1
      ? flight?.maximum_space
      : parseFloat(totalWeight).toFixed(1) === "0.0"
        ? 0.1
        : parseFloat(totalWeight).toFixed(1);
  const luggageSpaceAmount = PriceCalculator(weight).senderCost;
  const serviceCharge = 0.24 * (PriceCalculator(weight).senderCost - 25);

  const totalCharge =
    parseFloat(luggageSpaceAmount) + parseFloat(serviceCharge) + totalPrice;

  const initiatePaymentConfig = async (id) => {
    const data = JSON.stringify({
      booking_id: id,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/initiate-payment/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      if (response?.data) {
        window.localStorage.setItem(
          "sdkjuiagiufdwyifdyawefuvwevyd7fuvyaewvtdfvyauewdfewthsuuysdfgduy",
          JSON.stringify(response.data)
        );
        window.location.href = response?.data?.data?.authorization_url;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);

    // Determine the sender name and number based on the recipient checkbox state
    const itemRecipientName = isRecipientSameAsUser ? username : recipientName;
    const itemRecipientPhone = isRecipientSameAsUser ? phoneNumber : recipientPhone;

    const data = JSON.stringify({
      flight_book: flight.id,
      item_description: "Market Place",
      item_weight:
        parseFloat(totalWeight).toFixed(1) === "0.0"
          ? 0.1
          : parseFloat(totalWeight).toFixed(1),
      item_fee: parseFloat(totalCharge).toFixed(2),
      sender: userID,
      carrier_earnings: CarrierPriceCalculator(
        totalWeight,
        PriceCalculator(totalWeight).senderCost
      ),
      recipient_name: itemRecipientName,
      recipient_number: itemRecipientPhone,
      request_type: 3,
      scraped_product_ids: productIds,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/booking/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      data: data,
    };
    try {
      await axios
        .request(config)
        .then(async (response) => {
          if (response?.data) {
            await initiatePaymentConfig(response.data?.id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      if (error?.response?.data?.messages) {
        if (
          error?.response?.data?.messages[0]?.message ===
          "Token is invalid or expired"
        ) {
          dispatch(sessionTrue());
        }
      }
    }
  };

  // Handler for checkbox toggle
  const handleCheckboxChange = () => {
    setIsRecipientSameAsUser(!isRecipientSameAsUser);
    if (!isRecipientSameAsUser) {
      setRecipientName(username);
      setRecipientPhone(phoneNumber);
    } else {
      setRecipientName("");
      setRecipientPhone("");
    }
  };
  return (
    <form
      onSubmit={handleSubmit}
      className="w-full flex flex-col lg:flex-row gap-[53px] lg:gap-5 justify-between pt-[119px] sm:pt-[176px] px-[2rem] md:px-[5rem]"
    >
      {/**Share product link */}
      <div className=" w-full lg:w-1/2 lg:max-w-[602px] flex flex-col">
        <div className="font-bold flex flex-col gap-[9px] ">
          <h1 className=" text-[25px] sm:text-[35px] leading-[30.33px] sm:leading-[42.46px]">
            Order summary
          </h1>
          <h1 className=" text-[14px] sm:text-[16px] leading-[16.98px] sm:leading-[19.41px]">
            Almost there!
          </h1>
          <h1 className=" font-normal text-[16px] leading-[19.41px] text-[#AAAAAA]">
            Here's your order summary. Give it a quick look before you confirm
            to make sure everything's spot on
          </h1>
        </div>

        {/**Weight Range */}
        <div className=" w-full flex gap-[7px] mt-[33px]">
          <h1 className="text-[#AAAAAA] text-[16px] leading-[19.41px]">
            Minimum and maximum space required
          </h1>
          <div className="flex font-bold text-[#EB0728] text-[16px] leading-[19.41px]">
            {flight?.minimum_space < 1 ? (
              <h1>{flight?.maximum_space}kg</h1>
            ) : (
              <h1>
                {flight?.minimum_space}kg - {flight.maximum_space}kg
              </h1>
            )}
          </div>
        </div>

        {/**Image */}
        <img
          src={`https://${flight?.airport_img}`}
          alt=""
          className=" h-[300px] sm:h-[506px] sm:mt-[37px]  rounded-[30px]"
        />
      </div>

      {/**Order Summary */}
      {/* sm:w-4/5 sm:max-w-[37.5rem] md:w-3/4 md:max-w-[43.75rem] lg:w-1/2 lg:max-w-[35.5rem] h-auto py-[2.625rem] sm:py-[2.75rem] lg:py-[3rem] */}
      <div className=" px-[2rem] md:px-[60px] w-full flex flex-col lg:w-1/2  h-[714px] py-[42px] rounded-[30px] border-solid border-[1px] border-[#C1C1C180] ">
        <div className="w-full flex flex-col mt-4">
          {/*Second step */}
          <div className=" w-full flex items-center justify-center">
            {/**Number 1 */}
            <div className=" w-[25px] h-[25px] flex items-center justify-center text-center bg-[#D9D9D9] font-bold text-[#707070] text-[12px] leading-[14.56px] rounded-full">
              1
            </div>
            {/**Line */}
            <div className=" w-full max-w-[180px] border-dashed border-t-[1px] border-t-[#AAAAAA]" />
            {/**Number 2 */}
            <div className=" w-[25px] h-[25px] flex items-center justify-center text-center bg-[#EB0728] font-bold text-white text-[12px] leading-[14.56px] rounded-full">
              2
            </div>
          </div>

          {/**Market item details */}
          <div className="my-5 flex flex-col max-h-[100px] py-4 overflow-y-auto gap-4 custom-scrollbar">
            {marketLinks.map((marketLink, index) => {
              productIds.push(marketLink?.productId);
              return (
                <div key={index} className="w-full flex justify-between">
                  {/* Name and Weight */}
                  <div className="flex flex-col gap-[6px]">
                    <div className="tooltip" title={marketLink?.product_name}>
                      <h1 className="cursor-pointer font-bold text-[16px] leading-[19.41px]">
                        {marketLink?.product_name?.length > 20
                          ? marketLink?.product_name?.slice(0, 20) + "..."
                          : marketLink?.product_name}
                      </h1>
                    </div>
                    <p className="text-[16px] leading-[19.41px] text-[#AAAAAA]">
                      Item weight -{" "}
                      {parseFloat(marketLink?.weight).toFixed(1) === "0.0"
                        ? 0.1
                        : parseFloat(marketLink?.weight).toFixed(1)}
                      kg
                    </p>
                  </div>

                  {/* Price and Quantity */}
                  <div className="flex flex-col gap-[6px] items-end justify-end">
                    <h1 className="font-bold text-[16px] leading-[19.41px]">
                      ${marketLink?.price}
                    </h1>
                    <p className="text-[16px] leading-[19.41px] text-[#AAAAAA]">
                      Qty {marketLink?.quantity}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>

        </div>

        {/**Price break down and buttons */}
        <div className=" w-full gap-4 ">
          {/**Line */}
          {/* <div className="w-full mt-[29px] mb-[25px] border-solid border-b-[1px] border-b-[#C1C1C180]" /> */}

          <div className="bg-[#E5E5E5] bg-opacity-50 gap-2 rounded-[20px] flex flex-col p-5 sm:px-5 sm:text-base">
            {/* <div className="w-full flex flex-col gap-[10px] px-[5px] sm:px-[40px]"> */}
            {/**Total Weight */}
            <div className=" w-full flex justify-between">
              <p className=" text-[16px] leading-[19.41px] text-[#AAAAAA]">
                Total weight
              </p>
              <h1 className=" font-bold text-[16px] leading-[19.41px]">
                {parseFloat(totalWeight).toFixed(1) === "0.0"
                  ? 0.1
                  : parseFloat(totalWeight).toFixed(1)}
                kg
              </h1>
            </div>
            {/**Subtotal */}
            <div className=" w-full flex justify-between">
              <p className=" text-[16px] leading-[19.41px] text-[#AAAAAA]">
                Subtotal
              </p>
              <h1 className=" font-bold text-[16px] leading-[19.41px]">
                ${parseFloat(totalPrice).toFixed(2)}
              </h1>
            </div>

            {/**Luggage space*/}
            <div className=" w-full flex justify-between">
              <p className=" text-[16px] leading-[19.41px] text-[#AAAAAA]">
                Luggage space
              </p>
              <h1 className=" font-bold text-[16px] leading-[19.41px]">
                ${parseFloat(luggageSpaceAmount).toFixed(2)}
              </h1>
            </div>

            {/**Goodifly service charges*/}
            <div className=" w-full flex justify-between">
              <p className=" text-[16px] leading-[19.41px] text-[#AAAAAA]">
                Goodifly service charges
              </p>
              <h1 className=" font-bold text-[16px] leading-[19.41px]">
                ${parseFloat(serviceCharge).toFixed(2)}
              </h1>
            </div>
            {/**Total*/}
            <div className=" w-full flex justify-between mt-4">
              <p className=" text-[16px] leading-[19.41px] text-[#AAAAAA]">
                Total
              </p>
              <h1 className=" font-bold text-[16px] leading-[19.41px]">
                ${parseFloat(totalCharge).toFixed(2)}
              </h1>
            </div>
            {/* </div> */}
          </div>

          {/**Recipient Details */}
          <div className="mt-10">
            <p className="font-bold text-[16px] leading-[19.41px] ">
              Recipient’s details
            </p>
            <div className=" mt-6 flex gap-[7px] items-center mb-3 text-[#AAAAAA] text-[14px]">
              <input
                type="checkbox"
                checked={isRecipientSameAsUser}
                onChange={handleCheckboxChange}
                className="cursor-pointer w-[20px] h-[20px] accent-[#EB0728]"
              />
              <label>Tick the box to confirm that you are the recipient</label>
            </div>

            {!isRecipientSameAsUser && (
              <RecipientDetails
                recipientName={recipientName}
                recipientPhone={recipientPhone}
                onNameChange={setRecipientName}
                onPhoneChange={setRecipientPhone}
              />
            )}
          </div>

          {/**Buttons */}
          <div className="flex gap-4 justify-between my-4">
            {/**Back button */}
            <button
              onClick={() => goBack()}
              className=" mt-auto w-full h-[40px] min-h-[40px] rounded-[80px] border-solid border-[1px] border-[#AAAAAA] flex items-center justify-center text-center text-[#AAAAAA] text-[16px] leading-[19.41px]"
            >
              Back
            </button>
            {/**Pay button */}
            <button
              disabled={disabled}
              type="submit"
              className=" disabled:bg-[#F4C5CC] disabled:cursor-not-allowed mt-auto w-full h-[40px] min-h-[40px] rounded-[80px] bg-[#EB0728] flex items-center justify-center text-center text-[#FFFFFF] text-[16px] leading-[19.41px]"
            >
              Pay
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default MarketOrderSummary;
