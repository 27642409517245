import React from "react";
import { IoCloseOutline } from "react-icons/io5";

const ClearableInput = ({
  value,
  onChange,
  placeholder = "Enter text...",
  isClearDisabled,
  className = "",
}) => {
  // Handle clearing the input field
  const handleClear = () => {
    onChange("");
  };

  return (
    <div className="relative w-full">
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        className={`w-full p-2 pr-10 border border-gray-300 rounded-[2rem] ${className}`}
      />
      {value && !isClearDisabled ? (
        <button
          type="button"
          onClick={handleClear}
          className="absolute right-[1.125rem] top-1/2 transform -translate-y-1/2 w-[1.25rem] h-[1.25rem] text-[1.5rem]  text-[#AAAAAA]"
        >
          <IoCloseOutline />
        </button>
      ) : null}
    </div>
  );
};

export default ClearableInput;