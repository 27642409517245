import React from "react";

const RecipientDetails = ({
  recipientName,
  recipientPhone,
  onNameChange,
  onPhoneChange,
}) => {
  return (
    <div className="recipient-details">
      <div className="mb-4">
        <label
          className="block text-[#AAAAAA] text-sm font-bold mb-2"
          htmlFor="recipientName"
        ></label>
        <input
          type="text"
          id="recipientName"
          value={recipientName}
          onChange={(e) => onNameChange(e.target.value)}
          className="rounded-[5rem] bg-[#E5E5E580] bg-opacity-50 w-full py-2 px-[21px] text-black placeholder:text-[#AAAAAA] text-[14px] leading-[16.98px]"
          placeholder="Recipient name (optional)"
        />
      </div>
      <div className="mb-4">
        <label
          className="block text-[#AAAAAA] text-sm font-bold mb-2"
          htmlFor="recipientPhone"
        ></label>
        <input
          type="number"
          id="recipientPhone"
          value={recipientPhone}
          onChange={(e) => onPhoneChange(e.target.value)}
          className="rounded-[5rem] bg-[#E5E5E580] bg-opacity-50 w-full py-2 px-[21px] text-black placeholder:text-[#AAAAAA] text-[14px] leading-[16.98px]"
          placeholder="Recipient phone number"
          required
        />
      </div>
    </div>
  );
};

export default RecipientDetails;
