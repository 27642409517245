import React, { useState, useEffect, useRef } from "react";
import { BiChevronDown } from "react-icons/bi";

const LocationSelector = ({
  options,
  setDeliveryLocation,
  deliveryLocation,
  placeholder,
  style,
}) => {
  const [data, setData] = useState(options);
  const [inputValue, setInputValue] = useState(deliveryLocation);
  const [selected, setSelected] = useState("");
  const [open, setOpen] = useState(false);
  const selectorRef = useRef(null);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setDeliveryLocation(value);
    if (value) {
      setOpen(true);
    }
  };

  const hideSelector = (event) => {
    const modal = document.getElementById("modal");
    // Check if the clicked element is outside of the modal
    if (event.target !== modal && !modal.contains(event.target)) {
      setOpen(!open);
    }
  };

  // useEffect(() => {
  //   // fetch("https://restcountries.com/v2/all?fields=name")
  //   //   .then((res) => res.json())
  //   //   .then((data) => {
  //   //     setCountries(data);
  //   //   });
  //   const filteredOptions = options?.filter(
  //     (option) => option?.name.toLowerCase().includes(inputValue.toLowerCase())
  //   );
  //   setData(filteredOptions);
  // }, [inputValue, options]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // useEffect(() => {
  //   const filteredOptions = options?.filter(
  //     (option) => option?.name.toLowerCase().includes(inputValue.toLowerCase())
  //   );
  //   setData(filteredOptions);
  // }, [inputValue, options]);

  return (
    <div
      ref={selectorRef}
      className={`sm:w-[12.75rem] md:w-[243px] cursor-pointer`}
    >
      <div
        onClick={() => {
          setOpen(!open);
        }}
        className={` text-[1.125rem]  rounded-[2.5rem] h-[40px] bg-white border-solid border-[1px] border-[#C1C1C180] px-4 flex items-center justify-between ${
          !selected && "text-gray-700"
        }`}
      >
        <input
          type="text"
          id="input"
          value={inputValue === "" ? deliveryLocation : inputValue}
          onChange={handleInputChange}
          placeholder={placeholder}
          className="placeholder:text-[#AAAAAA] outline-none w-full"
        />
        <BiChevronDown
          color="#AAAAAA"
          size={25}
          className={`${open && "rotate-180"}`}
        />
      </div>
      {open || inputValue.length > 0 ? (
        <div>
          <div
            onClick={hideSelector}
            className=" cursor-pointer w-full h-full justify-center flex overflow-x-hidden overflow-y-auto z-[70] outline-none focus:outline-none scrollbar-hide"
          >
            <ul
              id="modal"
              className={` ${
                !open ? "hidden" : ""
              } rounded-[20px] mt-2 w-[90%] sm:w-[12.75rem] md:w-[243px] absolute shadow-input bg-white overflow-y-auto z-50 ${
                open
                  ? data?.length < 6
                    ? "max-h-fit"
                    : "h-[190px]"
                  : "max-h-0"
              }`}
            >
              {data && data.length > 0 ? (
                data?.map((item, index) => (
                  <li
                    key={index}
                    className={`p-2 text-sm hover:bg-[#EB0728] hover:text-white
         ${
           item?.name.toLowerCase() === selected?.toLowerCase() &&
           "bg-[#EB0728] text-white"
         }
         ${
           item?.name.toLowerCase().includes(inputValue)
             ? "block"
             : !open || inputValue.length === 0
             ? "hidden"
             : "block"
         }`}
                    onClick={() => {
                      if (item?.name.toLowerCase() !== selected.toLowerCase()) {
                        setSelected(item?.name);
                        setOpen(false);
                        setInputValue(item?.name);
                        setDeliveryLocation(item?.name);
                        window.localStorage.setItem(
                          "syudusajgvdasvjkdvtsfysahjgycdsghvfdghvfgdjhbsgfbjsdhbfhbdsugyubseyuvkjbvbukdfjsbvytvfd",
                          JSON.stringify(item?.name)
                        );
                      } else {
                        setOpen(false);
                        setSelected(item?.name);
                        setInputValue(item?.name);
                        setDeliveryLocation(item?.name);
                        window.localStorage.setItem(
                          "syudusajgvdasvjkdvtsfysahjgycdsghvfdghvfgdjhbsgfbjsdhbfhbdsugyubseyuvkjbvbukdfjsbvytvfd",
                          JSON.stringify(item?.name)
                        );
                      }
                    }}
                  >
                    {item?.name}
                  </li>
                ))
              ) : (
                <li className="p-2 text-sm text-[#EB0728]">no results found</li>
              )}
            </ul>
          </div>
          <div className="opacity-70 fixed z-[60] bg-black"></div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default LocationSelector;