import React, { useEffect, useState, useMemo } from "react";
import LocationSelector from "./LocationSelector";
//Inbound
import SednerRecipientViewModal from "./InboundPopUps/SednerRecipientViewModal";
import MarketPlaceViewModal from "./InboundPopUps/MarketPlaceViewModal";
import FlaggedViewModal from "./InboundPopUps/FlaggedViewModal";
import SuccessModal from "./InboundPopUps/SuccessModal";
import NewInbound from "./InboundTabs/NewInbound";
import ReceiveInbound from "./InboundTabs/ReceiveInbound";
import ApproveInbound from "./InboundTabs/ApproveInbound";
import CompleteInbound from "./InboundTabs/CompleteInbound";
import FlaggedInbound from "./InboundTabs/FlaggedInbound";
//Outbound
import SednerRecipientOutboundViewModal from "./OutboundPopUps/SednerRecipientOutboundViewModal";
import MarketPlaceOutboundViewModal from "./OutboundPopUps/MarketPlaceOutboundViewModal";
import FlaggedOutboundViewModal from "./OutboundPopUps/FlaggedOutboundViewModal";
import SuccessOutboundModal from "./OutboundPopUps/SuccessOutboundModal";
import NewOutbound from "./OutboundTabs/NewOutbound";
import ReceiveOutbound from "./OutboundTabs/ReceiveOutbound";
import ApproveOutbound from "./OutboundTabs/ApproveOutbound";
import CompleteOutbound from "./OutboundTabs/CompleteOutbound";
import FlaggedOutbound from "./OutboundTabs/FlaggedOutbound";

const options = [{ name: "Accra" }, { name: "New York City" }];

const DeliveryMiles = ({
  access_token,
  inboundData,
  outboundData,
  setDeliveryLocation,
  deliveryLocation,
}) => {
  const tempInboundData = useMemo(() => {
    return (
      inboundData?.filter((item) => item?.from_city === deliveryLocation) || []
    );
  }, [inboundData, deliveryLocation]);

  const tempOutboundData = useMemo(() => {
    return (
      outboundData?.filter((item) => item?.to_city === deliveryLocation) || []
    );
  }, [outboundData, deliveryLocation]);

  const [switchNav, setSwitchNav] = useState(0);

  // Inbound states
  const [switchInbound, setSwitchInbound] = useState(0);
  const [viewSednerRecipientViewModal, setViewSednerRecipientViewModal] =
    useState(null);
  const [viewMarketPlaceViewModal, setViewMarketPlaceViewModal] =
    useState(null);
  const [viewFlaggedViewModal, setViewFlaggedViewModal] = useState(null);
  const [viewSuccessModal, setViewSuccessModal] = useState(null);

  const [newInbound, setNewInbound] = useState([]);
  const [receiveInbound, setReceiveInbound] = useState([]);
  const [approveInbound, setApproveInbound] = useState([]);
  const [completeInbound, setCompleteInbound] = useState([]);
  const [flaggedInbound, setFlaggedInbound] = useState([]);

  // Outbound states
  const [switchOutbound, setSwitchOutbound] = useState(0);
  const [
    viewSednerRecipientOutboundViewModal,
    setViewSednerRecipientOutboundViewModal,
  ] = useState(null);
  const [
    viewMarketPlaceOutboundViewModal,
    setViewMarketPlaceOutboundViewModal,
  ] = useState(null);
  const [viewFlaggedOutboundViewModal, setViewFlaggedOutboundViewModal] =
    useState(null);
  const [viewSuccessOutboundModal, setViewSuccessOutboundModal] =
    useState(null);

  const [newOutbound, setNewOutbound] = useState([]);
  const [receiveOutbound, setReceiveOutbound] = useState([]);
  const [approveOutbound, setApproveOutbound] = useState([]);
  const [completeOutbound, setCompleteOutbound] = useState([]);
  const [flaggedOutbound, setFlaggedOutbound] = useState([]);

  const SetSwitchTabInLocalStorage = (value) => {
    window.localStorage.setItem(
      "hasvytqftyujaesdgyudsgyudyusdsytgdhvsytdesghdvytdsddtysytdsghgdgtftyudt5afdauywe67waitie6r76gfetawf6uyfr5aw7565wafytr",
      JSON.stringify(value)
    );
  };
  const setInboundTabValueInLocalStorage = (value) => {
    window.localStorage.setItem(
      "hasvytqftyujaetftyudt5afdauywe67waitie6r76gfetawf6uyfr5aw7565wafytr",
      JSON.stringify(value)
    );
  };
  const setOutboundTabValueInLocalStorage = (value) => {
    window.localStorage.setItem(
      "hasvsjkdbysdsgsuygsyusgyugfsyytqftyujaetftyudt5afdauywe67waitie6r76gfetawf6uyfr5aw7565wafytr",
      JSON.stringify(value)
    );
  };

  useEffect(() => {
    const some_switchTabInLocalStorage = window.localStorage.getItem(
      "hasvytqftyujaesdgyudsgyudyusdsytgdhvsytdesghdvytdsddtysytdsghgdgtftyudt5afdauywe67waitie6r76gfetawf6uyfr5aw7565wafytr"
    );
    const some_deliveryLocation = window.localStorage.getItem(
      "syudusajgvdasvjkdvtsfysahjgycdsghvfdghvfgdjhbsgfbjsdhbfhbdsugyubseyuvkjbvbukdfjsbvytvfd"
    );
    const parsedDeliveryLocation = JSON.parse(some_deliveryLocation);
    const some_inboundTabValueInLocalStorage = window.localStorage.getItem(
      "hasvytqftyujaetftyudt5afdauywe67waitie6r76gfetawf6uyfr5aw7565wafytr"
    );
    const some_outboundTabValueInLocalStorage = window.localStorage.getItem(
      "hasvsjkdbysdsgsuygsyusgyugfsyytqftyujaetftyudt5afdauywe67waitie6r76gfetawf6uyfr5aw7565wafytr"
    );

    setSwitchNav(
      JSON.parse(some_switchTabInLocalStorage)
        ? JSON.parse(some_switchTabInLocalStorage)
        : 0
    );
    setDeliveryLocation(parsedDeliveryLocation);
    setSwitchInbound(
      JSON.parse(some_inboundTabValueInLocalStorage)
        ? JSON.parse(some_inboundTabValueInLocalStorage)
        : 0
    );
    setSwitchOutbound(
      JSON.parse(some_outboundTabValueInLocalStorage)
        ? JSON.parse(some_outboundTabValueInLocalStorage)
        : 0
    );
  }, [deliveryLocation]);

  useEffect(() => {
    // Inbound

    setNewInbound(
      tempInboundData?.filter((item) => item?.agent_approval_inbound === 1) ||
        []
    );
    setReceiveInbound(
      tempInboundData?.filter((item) => item?.agent_approval_inbound === 2) ||
        []
    );
    setApproveInbound(
      tempInboundData?.filter((item) => item?.agent_approval_inbound === 3) ||
        []
    );
    setCompleteInbound(
      tempInboundData?.filter((item) => item?.agent_approval_inbound === 4) ||
        []
    );
    setFlaggedInbound(
      tempInboundData?.filter((item) => item?.agent_approval_inbound >= 5) || []
    );

    // Outbound
    setNewOutbound(
      tempOutboundData?.filter((item) => item?.agent_approval_outbound === 1) ||
        []
    );
    setReceiveOutbound(
      tempOutboundData?.filter((item) => item?.agent_approval_outbound === 2) ||
        []
    );
    setApproveOutbound(
      tempOutboundData?.filter((item) => item?.agent_approval_outbound === 3) ||
        []
    );
    setCompleteOutbound(
      tempOutboundData?.filter((item) => item?.agent_approval_outbound === 4) ||
        []
    );
    setFlaggedOutbound(
      tempOutboundData?.filter((item) => item?.agent_approval_outbound >= 5) ||
        []
    );
  }, [tempInboundData, tempOutboundData]);

  // Auto Refresh
  useEffect(() => {
    const handleMouseOver = () => {
      const some_data = window.localStorage.getItem(
        "adiuiusgyudgsuwhywguyegerugerguyrgruygeygruytyvyvuert"
      );
    };

    window.addEventListener("mouseover", handleMouseOver);

    return () => {
      window.removeEventListener("mouseover", handleMouseOver);
    };
  }, []);


  return (
    <>
      {/**Inbound */}
      {viewSednerRecipientViewModal ? (
        <SednerRecipientViewModal
          access_token={access_token}
          setViewSednerRecipientViewModal={setViewSednerRecipientViewModal}
          viewSednerRecipientViewModal={viewSednerRecipientViewModal}
          setViewFlaggedViewModal={setViewFlaggedViewModal}
          setViewSuccessModal={setViewSuccessModal}
        />
      ) : null}
      {viewMarketPlaceViewModal ? (
        <MarketPlaceViewModal
          access_token={access_token}
          setViewMarketPlaceViewModal={setViewMarketPlaceViewModal}
          viewMarketPlaceViewModal={viewMarketPlaceViewModal}
          setViewFlaggedViewModal={setViewFlaggedViewModal}
          setViewSuccessModal={setViewSuccessModal}
        />
      ) : null}
      {viewFlaggedViewModal ? (
        <FlaggedViewModal
          access_token={access_token}
          setViewFlaggedViewModal={setViewFlaggedViewModal}
          viewFlaggedViewModal={viewFlaggedViewModal}
          setViewSuccessModal={setViewSuccessModal}
        />
      ) : null}
      {viewSuccessModal ? (
        <SuccessModal
          setViewSuccessModal={setViewSuccessModal}
          viewSuccessModal={viewSuccessModal}
        />
      ) : null}
      {/**Outbound */}
      {viewSednerRecipientOutboundViewModal ? (
        <SednerRecipientOutboundViewModal
          access_token={access_token}
          setViewSednerRecipientOutboundViewModal={
            setViewSednerRecipientOutboundViewModal
          }
          viewSednerRecipientOutboundViewModal={
            viewSednerRecipientOutboundViewModal
          }
          setViewFlaggedOutboundViewModal={setViewFlaggedOutboundViewModal}
          setViewSuccessOutboundModal={setViewSuccessOutboundModal}
        />
      ) : null}
      {viewMarketPlaceOutboundViewModal ? (
        <MarketPlaceOutboundViewModal
          access_token={access_token}
          setViewMarketPlaceOutboundViewModal={
            setViewMarketPlaceOutboundViewModal
          }
          viewMarketPlaceOutboundViewModal={viewMarketPlaceOutboundViewModal}
          setViewFlaggedOutboundViewModal={setViewFlaggedOutboundViewModal}
          setViewSuccessOutboundModal={setViewSuccessOutboundModal}
        />
      ) : null}
      {viewFlaggedOutboundViewModal ? (
        <FlaggedOutboundViewModal
          access_token={access_token}
          setViewFlaggedOutboundViewModal={setViewFlaggedOutboundViewModal}
          viewFlaggedOutboundViewModal={viewFlaggedOutboundViewModal}
          setViewSuccessOutboundModal={setViewSuccessOutboundModal}
        />
      ) : null}
      {viewSuccessOutboundModal ? (
        <SuccessOutboundModal
          setViewSuccessOutboundModal={setViewSuccessOutboundModal}
          viewSuccessOutboundModal={viewSuccessOutboundModal}
        />
      ) : null}

      <div className="bg-white pt-1/w-1/4 sm:pt-[161px]">
        <div className=" w-full flex justify-between">
          <div className="flex flex-col gap-[6px]">
            <h1 className=" xm:text-[20px] md:text-[25px] md:leading-[30.33px] font-bold">
              Manage Delivery Miles
            </h1>
            <p className="text-[16px] text-[#AAAAAA] leading-[19.41px]">
              Review inbound and outbound requests assigned to you
            </p>
          </div>

          {/**Selector */}
          <LocationSelector
            options={options}
            setDeliveryLocation={setDeliveryLocation}
            deliveryLocation={deliveryLocation}
            placeholder="Select location"
          />
        </div>

        {/** */}
        <div className="w-full flex flex-col mt-[47px]">
          {/** switch */}
          <div className=" whitespace-nowrap sm:px-[4.375rem] md:px-[5rem]  flex w-full justify-center  items-start">
            <div
              className={` ${switchNav !== 2 ? "sm:pr-[34px]" : ""} ${
                switchNav !== 0 ? " sm:pl-[32px]" : ""
              } bg-[#FFFFFF] border-solid border-[1px] border-[#C1C1C1] shadow-switchBar w-fit h-[45px] sm:h-[65px] rounded-[80px] p-[10px] flex gap-3 sm:gap-[37px] items-center`}
            >
              <div
                onClick={() => {
                  setSwitchNav(0);
                  SetSwitchTabInLocalStorage(0);
                }}
                className={` ${
                  switchNav === 0
                    ? "bg-[#EB0728] px-4 sm:px-0 py-[8px] sm:py-0 w-full sm:w-[180px]  sm:h-[45px] rounded-[30px] text-white flex items-center justify-center text-center"
                    : ""
                } text-xs xm:text-[14px] sm:text-[18px] font-semibold sm:font-bold cursor-pointer`}
              >
                INBOUND
              </div>
              <div
                onClick={() => {
                  setSwitchNav(1);
                  SetSwitchTabInLocalStorage(1);
                }}
                className={` ${
                  switchNav === 1
                    ? "bg-[#EB0728] px-4 sm:px-0 py-[8px] sm:py-0 w-full sm:w-[180px]  sm:h-[45px] rounded-[30px] text-white flex items-center justify-center text-center"
                    : " sm:ml-[43px]"
                } text-xs xm:text-[14px] sm:text-[18px] font-semibold sm:font-bold cursor-pointer`}
              >
                OUTBOUND
              </div>
            </div>
          </div>

          {switchNav === 0 ? (
            <div className="w-full flex flex-col">
              <div className=" w-full h-full mt-[23px] bg-[#FFFFFF] border-solid border-[1px] border-[#C1C1C180] rounded-[30px] pt-[35px] px-[20px] sm:px-[53px] pb-[30px] overflow-x-hidden overflow-y-scroll">
                {/**Mini nav */}
                <div className=" w-full flex flex-col">
                  <div className="w-full flex justify-between">
                    <div className="w-[16%] flex items-center justify-center">
                      <button
                        onClick={() => {
                          setSwitchInbound(0);
                          setInboundTabValueInLocalStorage(0);
                        }}
                        className="flex gap-2"
                      >
                        New
                        <div
                          className={`w-[22px] h-[22px] rounded-full text-[#ffffff] ${
                            switchInbound === 0 ? "bg-[#EB0728]" : " bg-black"
                          }`}
                        >
                          {newInbound?.length}
                        </div>
                      </button>
                    </div>
                    <div className="w-[16%] flex items-center justify-center">
                      <button
                        onClick={() => {
                          setSwitchInbound(1);
                          setInboundTabValueInLocalStorage(1);
                        }}
                        className="flex gap-2"
                      >
                        Received
                        <div
                          className={`w-[22px] h-[22px] rounded-full text-[#ffffff] ${
                            switchInbound === 1 ? "bg-[#EB0728]" : " bg-black"
                          }`}
                        >
                          {receiveInbound?.length}
                        </div>
                      </button>
                    </div>
                    <div className="w-[16%] flex items-center justify-center">
                      <button
                        onClick={() => {
                          setSwitchInbound(2);
                          setInboundTabValueInLocalStorage(2);
                        }}
                        className="flex gap-2"
                      >
                        Approved
                        <div
                          className={`w-[22px] h-[22px] rounded-full text-[#ffffff] ${
                            switchInbound === 2 ? "bg-[#EB0728]" : " bg-black"
                          }`}
                        >
                          {approveInbound?.length}
                        </div>
                      </button>
                    </div>
                    <div className="w-[16%] flex items-center justify-center">
                      <button
                        onClick={() => {
                          setSwitchInbound(3);
                          setInboundTabValueInLocalStorage(3);
                        }}
                        className="flex gap-2"
                      >
                        Completed
                        <div
                          className={`w-[22px] h-[22px] rounded-full text-[#ffffff] ${
                            switchInbound === 3 ? "bg-[#EB0728]" : " bg-black"
                          }`}
                        >
                          {completeInbound?.length}
                        </div>
                      </button>
                    </div>
                    <div className="w-[16%] flex items-center justify-center">
                      <button
                        onClick={() => {
                          setSwitchInbound(4);
                          setInboundTabValueInLocalStorage(4);
                        }}
                        className="flex gap-2"
                      >
                        Flagged
                        <div
                          className={`w-[22px] h-[22px] rounded-full text-[#ffffff] ${
                            switchInbound === 4 ? "bg-[#EB0728]" : " bg-black"
                          }`}
                        >
                          {flaggedInbound?.length}
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="w-full flex items-center justify-between">
                    {[0, 1, 2, 3, 4].map((index) => (
                      <div
                        key={index}
                        className={`${
                          switchInbound === index
                            ? "transform transition-all ease-in-out duration-500 w-[16%] min-w-[16%] h-[10px] rounded-[80px] bg-[#EB0728]"
                            : "w-full h-[1px] bg-[#C1C1C180] transition-all ease-in-out duration-500"
                        } mt-[13px]`}
                      />
                    ))}
                  </div>
                </div>

                {/**Item Table */}
                <div className=" mt-[25px] w-full h-[449px] rounded-[20px] border-solid border-[1px] border-[#C1C1C180] overflow-hidden">
                  {/**Heading */}
                  <div className="w-full h-[51px] rounded-t-[20px] bg-[#E5E5E580] flex items-center font-bold text-[14px] text-[#707070] leading-[16.98px] px-[19px]">
                    <div className=" w-[12.5%]">User</div>
                    <div className=" w-[12.5%]">Route</div>
                    <div className=" w-[12.5%]">Departure</div>
                    <div className=" w-[12.5%]">Arrival</div>
                    {switchInbound === 4 ? null : switchInbound === 3 ? (
                      <div className=" w-[12.5%]">
                        Drop off <br></br>(To Carrier)
                      </div>
                    ) : (
                      <div className=" w-[12.5%]">
                        Drop off <br></br>(incoming)
                      </div>
                    )}
                    <div className=" w-[12.5%]">Request type</div>
                    <div className=" w-[12.5%]">Carrier</div>
                    {switchInbound === 4 ? (
                      <div className=" w-[12.5%]">Comment</div>
                    ) : null}
                    <div className=" w-[12.5%]">Action</div>
                  </div>

                  {/**Items*/}
                  <div className=" w-full h-[395px] overflow-scroll">
                    {/**Item*/}

                    {deliveryLocation?.length > 1 ? (
                      switchInbound === 0 ? (
                        newInbound?.length > 0 ? (
                          newInbound?.map((item, index) => {
                            return (
                              <NewInbound
                                key={index}
                                item={item}
                                setViewSednerRecipientViewModal={
                                  setViewSednerRecipientViewModal
                                }
                                setViewMarketPlaceViewModal={
                                  setViewMarketPlaceViewModal
                                }
                              />
                            );
                          })
                        ) : (
                          <div className=" w-full h-full flex flex-col items-center justify-center">
                            <h1 className=" text-[18px] text-black leading-[21.83px]">
                              No new inbound requests for {deliveryLocation}
                            </h1>
                          </div>
                        )
                      ) : switchInbound === 1 ? (
                        receiveInbound?.length > 0 ? (
                          receiveInbound?.map((item, index) => {
                            return (
                              <ReceiveInbound
                                key={index}
                                item={item}
                                setViewSednerRecipientViewModal={
                                  setViewSednerRecipientViewModal
                                }
                                setViewMarketPlaceViewModal={
                                  setViewMarketPlaceViewModal
                                }
                              />
                            );
                          })
                        ) : (
                          <div className=" w-full h-full flex flex-col items-center justify-center">
                            <h1 className=" text-[18px] text-black leading-[21.83px]">
                              No recieved inbound requests for{" "}
                              {deliveryLocation}
                            </h1>
                          </div>
                        )
                      ) : switchInbound === 2 ? (
                        approveInbound?.length > 0 ? (
                          approveInbound?.map((item, index) => {
                            return (
                              <ApproveInbound
                                key={index}
                                item={item}
                                setViewSednerRecipientViewModal={
                                  setViewSednerRecipientViewModal
                                }
                                setViewMarketPlaceViewModal={
                                  setViewMarketPlaceViewModal
                                }
                              />
                            );
                          })
                        ) : (
                          <div className=" w-full h-full flex flex-col items-center justify-center">
                            <h1 className=" text-[18px] text-black leading-[21.83px]">
                              No approved inbound requests for{" "}
                              {deliveryLocation}
                            </h1>
                          </div>
                        )
                      ) : switchInbound === 3 ? (
                        completeInbound?.length > 0 ? (
                          completeInbound?.map((item, index) => {
                            return (
                              <CompleteInbound
                                key={index}
                                item={item}
                                setViewSednerRecipientViewModal={
                                  setViewSednerRecipientViewModal
                                }
                                setViewMarketPlaceViewModal={
                                  setViewMarketPlaceViewModal
                                }
                              />
                            );
                          })
                        ) : (
                          <div className=" w-full h-full flex flex-col items-center justify-center">
                            <h1 className=" text-[18px] text-black leading-[21.83px]">
                              No completed inbound requests for{" "}
                              {deliveryLocation}
                            </h1>
                          </div>
                        )
                      ) : switchInbound === 4 ? (
                        flaggedInbound?.length > 0 ? (
                          flaggedInbound?.map((item, index) => {
                            return (
                              <FlaggedInbound
                                key={index}
                                item={item}
                                setViewSednerRecipientViewModal={
                                  setViewSednerRecipientViewModal
                                }
                                setViewMarketPlaceViewModal={
                                  setViewMarketPlaceViewModal
                                }
                              />
                            );
                          })
                        ) : (
                          <div className=" w-full h-full flex flex-col items-center justify-center">
                            <h1 className=" text-[18px] text-black leading-[21.83px]">
                              No flagged inbound requests for {deliveryLocation}
                            </h1>
                          </div>
                        )
                      ) : null
                    ) : (
                      <div className=" w-full h-full flex flex-col gap-[18px] items-center justify-center">
                        <h1 className=" text-[18px] text-black leading-[21.83px]">
                          Choose a location to view inbound and outbound
                          requests
                        </h1>
                        <LocationSelector
                          options={options}
                          setDeliveryLocation={setDeliveryLocation}
                          deliveryLocation={deliveryLocation}
                          placeholder="Select location"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full flex flex-col">
              <div className=" w-full h-full mt-[23px] bg-[#FFFFFF] border-solid border-[1px] border-[#C1C1C180] rounded-[30px] pt-[35px] px-[20px] sm:px-[53px] pb-[30px] overflow-x-hidden overflow-y-scroll">
                {/**Mini nav */}
                <div className=" w-full flex flex-col">
                  <div className="w-full flex justify-between">
                    <div className="w-[16%] flex items-center justify-center">
                      <button
                        onClick={() => {
                          setSwitchOutbound(0);
                          setOutboundTabValueInLocalStorage(0);
                        }}
                        className="flex gap-2"
                      >
                        New
                        <div
                          className={`w-[22px] h-[22px] rounded-full text-[#ffffff] ${
                            switchOutbound === 0 ? "bg-[#EB0728]" : " bg-black"
                          }`}
                        >
                          {newOutbound?.length}
                        </div>
                      </button>
                    </div>
                    <div className="w-[16%] flex items-center justify-center">
                      <button
                        onClick={() => {
                          setSwitchOutbound(1);
                          setOutboundTabValueInLocalStorage(1);
                        }}
                        className="flex gap-2"
                      >
                        Received
                        <div
                          className={`w-[22px] h-[22px] rounded-full text-[#ffffff] ${
                            switchOutbound === 1 ? "bg-[#EB0728]" : " bg-black"
                          }`}
                        >
                          {receiveOutbound?.length}
                        </div>
                      </button>
                    </div>
                    <div className="w-[16%] flex items-center justify-center">
                      <button
                        onClick={() => {
                          setSwitchOutbound(2);
                          setOutboundTabValueInLocalStorage(2);
                        }}
                        className="flex gap-2"
                      >
                        Approved
                        <div
                          className={`w-[22px] h-[22px] rounded-full text-[#ffffff] ${
                            switchOutbound === 2 ? "bg-[#EB0728]" : " bg-black"
                          }`}
                        >
                          {approveOutbound?.length}
                        </div>
                      </button>
                    </div>
                    <div className="w-[16%] flex items-center justify-center">
                      <button
                        onClick={() => {
                          setSwitchOutbound(3);
                          setOutboundTabValueInLocalStorage(3);
                        }}
                        className="flex gap-2"
                      >
                        Completed
                        <div
                          className={`w-[22px] h-[22px] rounded-full text-[#ffffff] ${
                            switchOutbound === 3 ? "bg-[#EB0728]" : " bg-black"
                          }`}
                        >
                          {completeOutbound?.length}
                        </div>
                      </button>
                    </div>
                    <div className="w-[16%] flex items-center justify-center">
                      <button
                        onClick={() => {
                          setSwitchOutbound(4);
                          setOutboundTabValueInLocalStorage(4);
                        }}
                        className="flex gap-2"
                      >
                        Flagged
                        <div
                          className={`w-[22px] h-[22px] rounded-full text-[#ffffff] ${
                            switchOutbound === 4 ? "bg-[#EB0728]" : " bg-black"
                          }`}
                        >
                          {flaggedOutbound?.length}
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="w-full flex items-center justify-between">
                    {[0, 1, 2, 3, 4].map((index) => (
                      <div
                        key={index}
                        className={`${
                          switchOutbound === index
                            ? "transform transition-all ease-in-out duration-500 w-[16%] min-w-[16%] h-[10px] rounded-[80px] bg-[#EB0728]"
                            : "w-full h-[1px] bg-[#C1C1C180] transition-all ease-in-out duration-500"
                        } mt-[13px]`}
                      />
                    ))}
                  </div>
                </div>

                {/**Item Table */}
                <div className=" mt-[25px] w-full h-[449px] rounded-[20px] border-solid border-[1px] border-[#C1C1C180] overflow-hidden">
                  {/**Heading */}
                  <div className="w-full h-[51px] rounded-t-[20px] bg-[#E5E5E580] flex items-center font-bold text-[14px] text-[#707070] leading-[16.98px] px-[19px]">
                    <div className=" w-[12.5%]">User</div>
                    <div className=" w-[12.5%]">Route</div>
                    <div className=" w-[12.5%]">Departure</div>
                    <div className=" w-[12.5%]">Arrival</div>
                    <div className=" w-[12.5%]">Request type</div>
                    <div className=" w-[12.5%]">Carrier</div>
                    {switchOutbound === 4 ? (
                      <div className=" w-[12.5%]">Comment</div>
                    ) : null}
                    <div className=" w-[12.5%]">Action</div>
                  </div>

                  {/**Items*/}
                  <div className=" w-full h-[395px] overflow-scroll">
                    {/**Item*/}

                    {deliveryLocation.length > 1 ? (
                      switchOutbound === 0 ? (
                        newOutbound?.length > 0 ? (
                          newOutbound?.map((item, index) => {
                            return (
                              <NewOutbound
                                key={index}
                                item={item}
                                setViewSednerRecipientOutboundViewModal={
                                  setViewSednerRecipientOutboundViewModal
                                }
                                setViewMarketPlaceOutboundViewModal={
                                  setViewMarketPlaceOutboundViewModal
                                }
                              />
                            );
                          })
                        ) : (
                          <div className=" w-full h-full flex flex-col items-center justify-center">
                            <h1 className=" text-[18px] text-black leading-[21.83px]">
                              No new outbound requests for {deliveryLocation}
                            </h1>
                          </div>
                        )
                      ) : switchOutbound === 1 ? (
                        receiveOutbound?.length > 0 ? (
                          receiveOutbound?.map((item, index) => {
                            return (
                              <ReceiveOutbound
                                key={index}
                                item={item}
                                setViewSednerRecipientOutboundViewModal={
                                  setViewSednerRecipientOutboundViewModal
                                }
                                setViewMarketPlaceOutboundViewModal={
                                  setViewMarketPlaceOutboundViewModal
                                }
                              />
                            );
                          })
                        ) : (
                          <div className=" w-full h-full flex flex-col items-center justify-center">
                            <h1 className=" text-[18px] text-black leading-[21.83px]">
                              No received outbound requests for{" "}
                              {deliveryLocation}
                            </h1>
                          </div>
                        )
                      ) : switchOutbound === 2 ? (
                        approveOutbound?.length > 0 ? (
                          approveOutbound?.map((item, index) => {
                            return (
                              <ApproveOutbound
                                key={index}
                                item={item}
                                setViewSednerRecipientOutboundViewModal={
                                  setViewSednerRecipientOutboundViewModal
                                }
                                setViewMarketPlaceOutboundViewModal={
                                  setViewMarketPlaceOutboundViewModal
                                }
                              />
                            );
                          })
                        ) : (
                          <div className=" w-full h-full flex flex-col items-center justify-center">
                            <h1 className=" text-[18px] text-black leading-[21.83px]">
                              No approved outbound requests for{" "}
                              {deliveryLocation}
                            </h1>
                          </div>
                        )
                      ) : switchOutbound === 3 ? (
                        completeOutbound?.length > 0 ? (
                          completeOutbound?.map((item, index) => {
                            return (
                              <CompleteOutbound
                                key={index}
                                item={item}
                                setViewSednerRecipientOutboundViewModal={
                                  setViewSednerRecipientOutboundViewModal
                                }
                                setViewMarketPlaceOutboundViewModal={
                                  setViewMarketPlaceOutboundViewModal
                                }
                              />
                            );
                          })
                        ) : (
                          <div className=" w-full h-full flex flex-col items-center justify-center">
                            <h1 className=" text-[18px] text-black leading-[21.83px]">
                              No completed outbound requests for{" "}
                              {deliveryLocation}
                            </h1>
                          </div>
                        )
                      ) : switchOutbound === 4 ? (
                        flaggedOutbound?.length > 0 ? (
                          flaggedOutbound?.map((item, index) => {
                            return (
                              <FlaggedOutbound
                                key={index}
                                item={item}
                                setViewSednerRecipientOutboundViewModal={
                                  setViewSednerRecipientOutboundViewModal
                                }
                                setViewMarketPlaceOutboundViewModal={
                                  setViewMarketPlaceOutboundViewModal
                                }
                              />
                            );
                          })
                        ) : (
                          <div className=" w-full h-full flex flex-col items-center justify-center">
                            <h1 className=" text-[18px] text-black leading-[21.83px]">
                              No flagged outbound requests for{" "}
                              {deliveryLocation}
                            </h1>
                          </div>
                        )
                      ) : null
                    ) : (
                      <div className=" w-full h-full flex flex-col gap-[18px] items-center justify-center">
                        <h1 className=" text-[18px] text-black leading-[21.83px]">
                          Choose a location to view inbound and outbound
                          requests
                        </h1>
                        <LocationSelector
                          options={options}
                          setDeliveryLocation={setDeliveryLocation}
                          deliveryLocation={deliveryLocation}
                          placeholder="Select location"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DeliveryMiles;